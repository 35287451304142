

$(document).ready(function() {
	$("#offcanvas").mmenu({
		// options
	}, {
		// configuration
		offCanvas: {
			pageSelector: ".page"
		}
	});
});
